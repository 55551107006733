import Spinner from "../assets/tube-spinner.svg";

export default function Loader() {
  return (
    <div className="loader w-1/3 text-center mx-auto">
      <img src={Spinner} alt="" />
      <span className="text-lg font-bold">Please wait...</span>
    </div>
  );
}
