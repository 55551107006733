import { ButtonSelectionPropTypes } from "../../constants";
import { Badge, SupText, TextEntry } from "./Shared";

export function CircularButton({ answer, isAnswerSelected, changeAnswer }) {
  const borderClasses = isAnswerSelected(answer.value) ? "border-pink-500" : "border-grey-500";
  const textOnly = answer.icon == null;
  const content = textOnly ? (
    <div className="quiz-service-num text-lg uppercase font-bold text-grey-lighter my-4 px-1 mx-auto">{answer.text}</div>
  ) : (
    <img src={answer.icon} alt={`${answer.text} icon`} className="object-contain w-10 h-10 m-auto" />
  );
  const selectKeys = [" ", "Enter"];
  const onKeyDown = (event) => {
    if (selectKeys.includes(event.key)) {
      changeAnswer(answer.value);
    }
  };

  return (
    <div className={`min-h-full ${textOnly ? "h-28" : "h-34"} w-28 sm:w-32 flex-initial`}>
      <div
        id={answer.value}
        className={`${borderClasses} overflow-hidden h-20 w-20 m-auto bg-white border-4 border-solid content-center rounded-full text-center cursor-pointer`}
        onClick={() => changeAnswer(answer.value)}
        tabIndex={0}
        onKeyDown={onKeyDown}
      >
        {content}
      </div>
      {!textOnly && (
        <div className={`text-xs md:text-sm text-white my-4 text-center`}>
          {answer.badge && <Badge {...answer.badge}></Badge>}
          {answer.text && <TextEntry text={answer.text} value={answer.value} />}
          {answer.supText && <SupText text={answer.supText} />}
        </div>
      )}
    </div>
  );
}
CircularButton.propTypes = ButtonSelectionPropTypes;
