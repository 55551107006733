import { useEffect, useRef } from "react";
import { useStoreSelectors } from "../store";

export default function LegalConsent() {
  const legalPlaceholderRef = useRef(null);
  const { selectLegalTextColor, selectLegalTextSelector } = useStoreSelectors();
  const legalTextColor = selectLegalTextColor();
  const legalTextSelector = selectLegalTextSelector();

  useEffect(() => {
    const placeholderNode = legalPlaceholderRef.current;
    if (placeholderNode == null) {
      return;
    }

    const legalNode = document.querySelector(legalTextSelector);
    if (legalNode == null) {
      console.warn("Failed to find legal node");
      return;
    }

    const clonedLegalNode = legalNode.cloneNode(true);
    clonedLegalNode.removeAttribute("id");
    clonedLegalNode.removeAttribute("style");
    placeholderNode.replaceChildren(clonedLegalNode);
  }, [legalPlaceholderRef, legalTextSelector]);

  return <div ref={legalPlaceholderRef} className={`text-xxs mb-4 text-center ${legalTextColor}`}></div>;
}
