export default function Accolades() {
  return (
    <div className="w-full max-w-sm flex flex-col items-start mx-auto md:mr-auto md:ml-8 mt-8 text-left">
      <div className="accolade-group-1 flex items-center gap-2 mb-4">
        <img
          className="accolade-check-1 w-3 h-3"
          src="https://res.cloudinary.com/laseraway-com/image/upload/v1656429007/cro/circle-check-regular_1_1.svg"
          alt="Over 3 Million Treatments Performed"
        />
        <div className="accolade-1 text-sm text-grey-dark">Over 3 Million Treatments Performed</div>
      </div>
      <div className="accolade-group-2 flex items-center gap-2 mb-4">
        <img
          className="accolade-check-2 w-3 h-3"
          src="https://res.cloudinary.com/laseraway-com/image/upload/v1656429007/cro/circle-check-regular_1_1.svg"
          alt="The Nation's Leader in Aesthetic Dermatology"
        />
        <div className="accolade-2 text-sm text-grey-dark">The Nation’s Leader In Aesthetic Dermatology</div>
      </div>
      <div className="accolade-group-3 flex items-center gap-2 mb-4">
        <img
          className="accolade-check-3 w-3 h-3"
          src="https://res.cloudinary.com/laseraway-com/image/upload/v1656429007/cro/circle-check-regular_1_1.svg"
          alt="20+ Board Certified Dermatologists"
        />
        <div className="accolade-3 text-sm text-grey-dark">20+ Board Certified Dermatologists</div>
      </div>
    </div>
  );
}
