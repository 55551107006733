import FormStep from "../FormStep";
import Accolades from "../Accolades";
import { NavigationButton } from "../NavigationButton";
import { BundleStepIndicator } from "../BundleStepIndicator";
import { useStoreActions, useStoreSelectors, useStoreState } from "../../store";
import LegalConsent from "../LegalConsent";
import { useEffect, useRef } from "react";
import { getThemeStyles, getThemeClassString } from "../../themes";

export default function BundleSelectorForm() {
  const { settings, flow, steps } = useStoreState();
  const { selectCurrentStep, selectHasPreviousStep, selectCanAdvance, selectNextButtonText } = useStoreSelectors();
  const { navigateBack, navigateForward } = useStoreActions();
  const { formEmphasis, showAccolades, indicator, theme } = settings;
  const currentStep = selectCurrentStep();
  const hasPreviousStep = selectHasPreviousStep();
  const canAdvance = selectCanAdvance();
  const nextButtonBackgroundClass = settings.nextButtonBackgroundClass ?? "bg-pink-electric";
  const formBodyClasses = formEmphasis ? "w-full h-full rounded-b-6xl border-4 border-zinc-200 border-solid border-t-0 bg-grey-light pt-8 pb-6" : "";
  const headerClasses = formEmphasis
    ? "flex flex-col rounded-t-6xl rounded-tr-6xl sm:flex-row-reverse bg-grey-dark p-8 justify-between w-full text-white"
    : "text-grey-dark mb-4";

  const headerMargins = formEmphasis ? "" : "mb-4";
  const formPadding = formEmphasis ? "form-emphasis px-5 lg:px-10" : "form-non-emphasis px-2";
  const nextButtonText = selectNextButtonText();

  const showLegalConsent = currentStep.lastStep && settings.showLegalConsentText;
  const { selectionStepId } = indicator;
  const selectionStep = steps.find((step) => step.id === selectionStepId);
  const selectedAnswers = selectionStep.answers.filter((answer) => answer.selected);

  const showBackButton = settings.backButton && hasPreviousStep;

  const themeClasses = getThemeStyles(theme);

  const questionElementRef = useRef(null);
  useEffect(() => {
    const questionElement = questionElementRef.current;
    if (questionElement == null) {
      return;
    }
    if (currentStep.firstStep) {
      return;
    }
    if (currentStep.answers?.some((answer) => answer.selected)) {
      return;
    }
    questionElement.focus();
  }, [currentStep]);

  return (
    <>
      <div>
        <div className="text-3xl text-center font-bold my-4">Bundle & Save</div>
      </div>
      <BundleStepIndicator steps={indicator.steps} selectedCount={selectedAnswers.length} />
      <div className={`${getThemeClassString(themeClasses, ["headerBackground", "headerTextColor", "topBorderRadius", "textCenter", "padding"], headerClasses )}`}>
        <div className={`font-medium ${headerMargins} text-xs uppercase`}>STEP {flow.length}</div>
        {currentStep && (
          <div className={`font-bold ${headerMargins} text-xl`} ref={questionElementRef}>
            {currentStep.question}
          </div>
        )}
      </div>
      <div className={`${getThemeClassString(themeClasses, ["backgroundColor", "paddingY", "paddingX", "bottomBorderRadius"], formBodyClasses )}`}>
        <div className={`${formPadding} w-full mx-auto`}>
          <FormStep />
          <div className="w-full mt-4 flex flex-col justify-between">
            {showLegalConsent && <LegalConsent />}
            <div className="flex flex-row gap-4">
              {showBackButton && <NavigationButton text="Previous" backgroundClasses={"bg-gray-400"} onNavButtonClicked={() => navigateBack()} />}

              <NavigationButton
                text={nextButtonText}
                backgroundClasses={nextButtonBackgroundClass}
                onNavButtonClicked={() => navigateForward()}
                disabled={!canAdvance}
              />
            </div>
          </div>
        </div>
        {showAccolades && <Accolades />}
      </div>
    </>
  );
}
