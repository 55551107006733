import { ButtonSelectionPropTypes } from "../../constants";
import { useStoreState } from "../../store";
import { Badge, SupText, TextEntry } from "./Shared";
import { getThemeStyles, getThemeClassString } from "../../themes";

export function AnswerButton({ answer, isAnswerSelected, changeAnswer }) {
  const { settings } = useStoreState();
  const { formEmphasis, theme } = settings;
  const themeClasses = getThemeStyles(theme);
  const selectedClasses = settings.buttonSelectedClasses ?? `bg-pink-electric outline outline-dashed outline-black outline-2 outline-offset-2 ${getThemeClassString(themeClasses, ["selectedTextColor"], 'text-white')}`;
  const regularClasses = formEmphasis ? "bg-white" : "bg-grey-light text-black";
  const textOnly = answer.color == null && answer.icon == null;
  const textClass = textOnly ? "text-2xl px-5 py-4 text-center font-semibold" : "text-xs sm:text-sm font-medium pt-1 xs:px-2";
  const selectKeys = [" ", "Enter"];
  const onKeyDown = (event) => {
    if (selectKeys.includes(event.key)) {
      changeAnswer(answer.value);
    }
  };

  return (
    <div
      id={answer.value}
className={`min-w-36 w-auto h-full min-h-fit py-3 px-3 xs:px-4 mr-2 mb-3 flex flex-col items-center ${getThemeClassString(themeClasses, ["buttonBgColor"], 'bg-grey-light')} rounded-3xl overflow-hidden cursor-pointer ${
  isAnswerSelected(answer.value) ? selectedClasses : regularClasses
}`}
      onClick={() => changeAnswer(answer.value)}
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      {answer.color && <p className="h-10 w-10 rounded-full pt-3" style={{ backgroundColor: answer.color }}></p>}
      {answer.icon && <img src={answer.icon} alt={`${answer.text} icon`} className="object-center h-10 max-w-10 w-auto" />}
      <div className={`text-center uppercase ${textClass}`}>
        {answer.badge && <Badge {...answer.badge}></Badge>}
        {answer.text && <TextEntry text={answer.text} value={answer.value} />}
        {answer.supText && <SupText text={answer.supText} />}
      </div>
    </div>
  );
}

AnswerButton.propTypes = ButtonSelectionPropTypes;
