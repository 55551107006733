import PropTypes from "prop-types";
import ReactPhoneNumberInput from "react-phone-number-input/input";
import { ValidationPropTypes } from "../constants";
import { useDebouncedCallback } from "../hooks/useDebouncedCallback";
import { useStoreActions, useStoreSelectors, useStoreState } from "../store";

export function PhoneInput({ inputData }) {
  const { selectIsExitModal, selectInputStyle, selectCanAdvance } = useStoreSelectors();
  const { changeInput, navigateForward } = useStoreActions();
  const { settings } = useStoreState();
  const isExitModal = selectIsExitModal();
  const inputStyle = selectInputStyle();
  const isPristine = !Object.prototype.hasOwnProperty.call(inputData.validation, "isValid");
  const isValid = inputData.validation.isValid;
  let classes = `w-full rounded border p-3 shadow shadow-gray-100 mt-2 appearance-none outline-none text-neutral-800 col-auto xxs:col-span-2 ${
    isValid || isPristine ? "border-gray-300" : "border-red-500"
  }`;

  if (isExitModal || inputStyle === "square") {
    classes = `w-full p-5 placeholder-grey-dark text-base uppercase font-bold mb-2 tracking-wider text-center animate-slideLeft ${
      isValid || isPristine
        ? "outline-gray-300 outline-2 outline-offset-1 focus:outline-dashed"
        : "outline-pink-error outline-2 outline-offset-4 outline-dashed"
    }`;
  }

  const errorClasses = settings.inputErrorClass ?? (isExitModal ? "text-white" : "text-red-500");

  const handleInputChange = useDebouncedCallback((value) => {
    changeInput({ name: inputData.name, value });
  }, 250);

  const handleInputKeyDown = useDebouncedCallback((event) => {
    if (event.key === "Enter" && selectCanAdvance()) {
      navigateForward();
    }
  }, 250);

  return (
    <>
      {inputData.label && <div className="font-bold mb-4 mt-4 text-xl">{inputData.label}</div>}
      <ReactPhoneNumberInput
        type="tel"
        autoComplete="tel-national"
        defaultCountry="US"
        name={inputData.name}
        value={inputData.value}
        placeholder={inputData.placeholder}
        aria-label={inputData.placeholder}
        className={`${classes}`}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
      {isValid == false && <div className={`mt-2 text-sm ${errorClasses}`}>{inputData.validation.error}</div>}
    </>
  );
}

PhoneInput.propTypes = {
  inputData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validation: ValidationPropTypes,
  }),
};
