import PropTypes from "prop-types";
import { ValidationPropTypes } from "../constants";
import { useStoreActions, useStoreSelectors } from "../store";

export function SelectInput({ inputData }) {
  const { selectIsExitModal, selectInputStyle } = useStoreSelectors();
  const { changeInput } = useStoreActions();
  const isExitModal = selectIsExitModal();
  const inputStyle = selectInputStyle();
  const isPristine = !Object.prototype.hasOwnProperty.call(inputData.validation, "isValid");
  const isValid = inputData.validation.isValid;

  const stateClasses = isValid || isPristine ? "border-gray-300" : "border-red-500";
  let classNames = `w-full rounded border p-3 shadow shadow-gray-100 mt-2 appearance-none outline-none text-neutral-800 col-auto xxs:col-span-2 ${stateClasses}`;
  if (isExitModal || inputStyle === "square") {
    classNames = `bg-white w-full h-16 focus:outline-dashed border border-solid border-grey-form p-4 text-grey-dark animate-slideLeft`;
  }
  const onInputChange = (payload) => changeInput(payload);
  // using string template just so the scoping knows it has to be applied there aswell since its dynamic
  const renderOption = (option) => (
    <option value={option.value} key={`${inputData.name}-${option.value}`} className={`${option.classList}`}>
      {option.text}
    </option>
  );
  return (
    <>
      <select
        onChange={(event) => onInputChange({ name: inputData.name, value: event.target.value })}
        name={inputData.name}
        size={inputData.size}
        placeholder={inputData.placeholder}
        multiple={inputData.multiple}
        aria-label={inputData.placeholder}
        className={`${classNames}`}
        tabIndex={0}
      >
        {inputData.mode !== "dom" && <option value="">{inputData.placeholder}</option>}
        {inputData.options &&
          inputData.options.map((option) =>
            option.type === "group" ? (
              <optgroup label={option.text} key={`${inputData.name}-${option.text}`}>
                {option.options.map(renderOption)}
              </optgroup>
            ) : (
              renderOption(option)
            )
          )}
      </select>
      {isValid == false && <div className="mt-2 text-sm text-red-500">{inputData.validation.error}</div>}
    </>
  );
}
const optionsPropTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string,
  classList: PropTypes.string,
};

SelectInput.propTypes = {
  inputData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    mode: PropTypes.oneOf(["dom", "remote", "static"]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape(optionsPropTypes)),
        ...optionsPropTypes,
      })
    ),
    validation: ValidationPropTypes,
  }),
  index: PropTypes.number,
};
