import PropTypes from "prop-types";

export default function StepIndicator({ total, current, selectedBgClass = "bg-white", baseBgClass = "bg-grey-soft", heigth = 1, showStepText = false, theme = null }) {
  let indicators = [];
  const stepHeight = `h-${heigth}`;
  const paddingX = theme ? 'px-4' : '';

  for (let index = 0; index < total; index++) {
    const backgroundClass = index + 1 <= current ? selectedBgClass : `${baseBgClass} opacity-50`;
    indicators.push(<div key={index} className={`${backgroundClass} w-full rounded ${stepHeight}`}></div>);
  }

  const stepperBottomMargin = showStepText ? "mb-4" : "mb-12";
  return (
    <>
      <div className={`flex flex-row overflow-hidden gap-x-4 ${stepperBottomMargin} mt-4 ${paddingX}`}>{indicators}</div>
      {showStepText && (
        <div className="uppercase text-base mb-4 text-center">
          STEP {current} OF {total}{" "}
        </div>
      )}
    </>
  );
}

StepIndicator.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  selectedBgClass: PropTypes.string,
  baseBgClass: PropTypes.string,
  heigth: PropTypes.number.isRequired,
  showStepText: PropTypes.bool,
};
