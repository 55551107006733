import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { StoreProvider } from "./FormProvider.jsx";
// uncomment the line bellow when in development
// import "./index-dev.css";
import "./index.css";

export const renderInstance = (node) => {
  const dataContainer = node?.parentNode?.querySelector(":scope > #rlf-data-container");
  if (dataContainer == null) {
    console.warn("Data container not found");
    return;
  }
  const rootNode = ReactDOM.createRoot(node);
  rootNode.render(
    <StoreProvider>
      <App dataContainer={dataContainer} />
    </StoreProvider>
  );
};

const rootNodes = Array.from(document.querySelectorAll(".rlf-root"));
if (rootNodes.length === 0) {
  const rootNode = document.getElementById("rlf-root");
  if (rootNode == null) {
    console.error("Failed to find root element for react form");
  }
  renderInstance(rootNode);
} else {
  rootNodes.forEach(renderInstance);
}
