import StandardForm from "./Form/StandardForm";
import ExitModalForm from "./Form/ExitModalForm";
import DarkModeForm from "./Form/DarkModeForm";
import StepperListForm from "./Form/StepperListForm";
import { useStoreState } from "../store";
import BundleSelectorForm from "./Form/BundleSelectorForm";
import { FormTypes } from "../constants";

export default function Form() {
  const { settings } = useStoreState();
  const { formType } = settings;
  let formComponent = <StandardForm />;
  if (formType === FormTypes.ExitModal) {
    formComponent = <ExitModalForm />;
  }
  if (formType === FormTypes.DarkMode) {
    formComponent = <DarkModeForm />;
  }
  if (formType === FormTypes.StepperList) {
    formComponent = <StepperListForm />;
  }
  if (formType === FormTypes.BundleSelector) {
    formComponent = <BundleSelectorForm />;
  }

  return <div id="form-wrapper">{formComponent}</div>;
}
