import { ButtonSelectionPropTypes } from "../../constants";
import { useStoreState } from "../../store";
import { RadioButtonImage } from "../RadioButtonImage";
import { Badge, SupText, TextEntry } from "./Shared";

export function ListItemButton({ answer, isAnswerSelected, changeAnswer }) {
  const { settings } = useStoreState();
  const selectedClasses = settings.buttonSelectedClasses ?? "border-red-laseraway";
  const regularClasses = "border-grey-light";
  const isSelected = isAnswerSelected(answer.value);
  const textClass = `text-sm sm:text-base font-bold px-2`;
  const stateClasses = isSelected ? selectedClasses : regularClasses;
  const listButtonClasses = settings.listbutton?.classes ?? "text-center self-start"; 
  const theme = settings.theme;
  const selectKeys = [" ", "Enter"];
  const onKeyDown = (event) => {
    if (selectKeys.includes(event.key)) {
      changeAnswer(answer.value);
    }
  };

  return (
    <div
      id={answer.value}
      className={`w-${theme ? 'auto' : 'full'} h-full min-h-fit py-3 px-3 xs:px-4 mr-2 mb-3 flex flex-row bg-white items-start border-2 border-grey-light rounded-lg overflow-hidden cursor-pointer ${stateClasses}`}
      onClick={() => changeAnswer(answer.value)}
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      <RadioButtonImage title={answer.text} isSelected={isSelected} className="self-start sm:mt-1" />
      {answer.icon && <img src={answer.icon} alt={`${answer.text} icon`} className="object-center max-w-10 h-auto mx-2" />}
      <div className={`${listButtonClasses} ${textClass}`}>
        {answer.badge && <Badge {...answer.badge}></Badge>}
        {answer.text && <TextEntry text={answer.text} value={answer.value} />}
        {answer.supText && <SupText text={answer.supText} />}
      </div>
    </div>
  );
}

ListItemButton.propTypes = ButtonSelectionPropTypes;
